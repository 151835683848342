import React, { ReactNode } from 'react';
import Container from '../components/core/layout/Layout/Container';
import Heading from '../components/core/typography/Heading';
import List from '../components/core/lists/List';
import ListItem from '../components/core/lists/ListItem';
import Button from '../components/core/buttons/Button';
import FaqAccordion from '../components/views/FaqAccordion/FaqAccordion';
import SEO from '../components/core/meta/SEO';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  getAppRedirectUri,
  HOSTNAME,
} from '../components/utils/getAppRedirectUri';
import { PrezziCommissioniQuery } from '../../generated/graphql-types';
import OrangeBanner2 from '../components/partials/OrangeBanner2/OrangeBanner2';
import SecurePayment from '../components/partials/SecurePayment/SecurePayment';
import ThreeSteps from '../components/partials/ThreeSteps/ThreeSteps';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../contentful/render-options';
import PartnerBanner from '../components/partials/PartnerBanner/PartnerBanner';
import OtherServices from '../components/views/OtherServices/OtherServices';
import useDatalayer from '../../hooks/use-datalayer';

const PricesCompanyPage = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const prezziCommissioni = useStaticQuery<PrezziCommissioniQuery>(graphql`
    query PrezziCommissioni {
      commisioniFaq: allContentfulFaq(
        filter: { category: { eq: "Prezzi Commissioni Page" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  const datalayer = useDatalayer();

  return (
    <div className="pb-16">
      <SEO title="Prezzi compagnia" url="/prezzi-compagnia" />
      <section className="py-10">
        <Container>
          <Heading variant="h1" className="mb-10">
            Prezzi del servizio
          </Heading>
          <Heading variant="h3">Commissioni “sotto casa”</Heading>
          <p className="text-paragraph mb-16">
            <b>Cosa prevede?</b> Che ai nostri operatori vengano affidate le
            commissioni da svolgere “nel quartiere”. Non ci sono vincoli di
            tipologia: i nostri UGO possono recarsi dove gli indicherete, come
            fareste voi, per{' '}
            <b>acquistare con cura i vostri prodotti preferiti.</b>
            Restare nel quartiere è importante perché significa che l’operatore
            può organizzarsi senza usare l’auto e senza costi aggiuntivi: alcuni
            si muovono a piedi, in motorino, in bicicletta. Siamo felici di fare
            le commissioni nel quartiere e raggiungere piccoli esercenti, negozi
            storici, botteghe, che non rientrano fra i servizi più usati online
            ma che fanno parte delle abitudini quotidiane di tutti noi.
          </p>
        </Container>
        <div className="bg-primary-300 py-16">
          <Container>
            <div className="flex flex-wrap -mx-5 mb-5 md:mb-0">
              <div className="w-full md:w-1/2 px-5 flex">
                <div className="p-8 rounded-card shadow-card bg-white flex flex-col flex-1">
                  <Heading variant="h4" className="mb-5 text-center">
                    Prima ora
                  </Heading>
                  <div className="text-center mb-5">
                    <span className="text-paragraph text-3xl font-bold text-primary-500">
                      €
                    </span>
                    <span className="text-paragraph text-7xl font-bold text-primary-500">
                      12,50
                    </span>
                  </div>
                  <div className="flex md:justify-center">
                    <List>
                      <ListItem className="font-bold">
                        Le commissioni vengono svolte nel quartiere.
                      </ListItem>
                      <ListItem className="font-bold">
                        Prevedono: spesa alimentare, farmacia, commissioni
                        varie, Poste, Banca, portare fuori il cane, pagare le
                        bollette, ecc.
                      </ListItem>
                      <ListItem className="font-bold">
                        Se si richiede la spesa, deve essere gestibile da un
                        operatore da solo e non sono ammessi più di 2 cestelli
                        di acqua.
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-5 flex">
                <div className="p-8 rounded-card shadow-card bg-white flex flex-col flex-1">
                  <Heading variant="h4" className="mb-5 text-center">
                    30 minuti aggiuntivi
                  </Heading>
                  <div className="text-center mb-5">
                    <span className="text-paragraph text-3xl font-bold text-primary-500">
                      €
                    </span>
                    <span className="text-paragraph text-7xl font-bold text-primary-500">
                      6,00
                    </span>
                  </div>
                  <div className="flex md:justify-center">
                    <List>
                      <ListItem className="font-bold">
                        Sono valide tutte le condizioni applicate alla prima
                        ora. Se si necessità di una commissione più lunga di
                        un’ora, si possono aggiungere slot di 30 minuti.
                        <br />
                        Se hai bisogno di una commissione che prevede uno
                        spostamento significativo, al costo orario devi sommare
                        il rimborso spese di 5€ ogni 10km.
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container className="py-16">
          <ThreeSteps />
        </Container>
        <OrangeBanner2 />
        <Container className="py-16 pb-20">
          <SecurePayment />
        </Container>
        <Container>
          <div className="flex justify-center">
            <Link to="/#">
              <Button variant="filled" color="primary">
                Prenota il servizio
              </Button>
            </Link>
          </div>
        </Container>
      </section>
      {prezziCommissioni.commisioniFaq.edges.length && (
        <section className="pb-16">
          <Container>
            <Heading variant="h2" className="mt-0">
              Domande frequenti
            </Heading>
            <FaqAccordion
              faqItems={prezziCommissioni.commisioniFaq.edges.map(
                ({ node }) => ({
                  answer: renderRichText(
                    // @ts-ignore
                    node.answer,
                    renderOptions
                  ) as ReactNode,
                  question: node.question.question,
                })
              )}
            />
          </Container>
        </section>
      )}
      <PartnerBanner />
      <Container className="pt-16">
        <OtherServices compagnia accompagnamento />
      </Container>
    </div>
  );
};

export default PricesCompanyPage;
