import Heading from '../../core/typography/Heading';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SecurePaymentQuery } from '../../../../generated/graphql-types';

const SecurePayment = () => {
  const prezziCompagnia = useStaticQuery<SecurePaymentQuery>(graphql`
    query SecurePayment {
      iconSafe: file(relativePath: { eq: "icon_safe.png" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      iconSafe2: file(relativePath: { eq: "icon_safe_2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `);
  return (
    <>
      <Heading variant="h3" className="text-center mb-16">
        Pagamento online diretto e sicuro
      </Heading>
      <div className="flex flex-wrap -mx-4 md:-mx-10">
        <div className="w-full md:w-1/2 px-4 md:px-10 mb-10 md:mb-0 flex flex-col">
          <div className="rounded-card p-8 shadow-card flex-1">
            <div className="flex justify-center mb-4">
              <GatsbyImage
                image={prezziCompagnia.iconSafe.childImageSharp.gatsbyImageData}
                alt="icon safe"
              />
            </div>
            <Heading variant="h5" className="text-center">
              Addebito post servizio
            </Heading>
            <p className="text-paragraph">
              In fase di prenotazione ti chiediamo di inserire nel profilo
              personale un metodo di pagamento valido. Per noi è una garanzia
              importante ma non ti viene addebitato nulla. Il pagamento avviene
              a fine servizio: il sistema calcola la durata effettiva e ti viene
              addebitato il costo esatto. In questo modo hai un servizio
              flessibile e paghi solo quello che usi!
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4 md:px-10 flex flex-col">
          <div className="rounded-card p-8 shadow-card flex-1">
            <div className="flex justify-center mb-4">
              <GatsbyImage
                image={
                  prezziCompagnia.iconSafe2.childImageSharp.gatsbyImageData
                }
                alt="icon safe 2"
              />
            </div>
            <Heading variant="h5" className="text-center">
              Pagamenti certificati e sicuri
            </Heading>
            <p className="text-paragraph">
              Il nostro sistema di pagamento è sicuro e tracciato. Si chiama
              Stripe e viene usato dai migliori servizi online. I tuoi dati sono
              gestiti in massima sicurezza e inaccessibili. Puoi inserire come
              metodo di pagamento qualsiasi carta abilitata per pagare online:
              carta di credito, Bancomat, prepagata. Per qualsiasi problema il
              nostro Servizio Clienti è pronto ad aiutarti.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurePayment;
