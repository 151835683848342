import { graphql, useStaticQuery } from 'gatsby';
import { ThreeStepsQuery } from '../../../../generated/graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Heading from '../../core/typography/Heading';
import ResponsiveList from '../../views/ResponsiveList/ResponsiveList';
import React from 'react';

const ThreeSteps = () => {
  const threeStepsData = useStaticQuery<ThreeStepsQuery>(graphql`
    query ThreeSteps {
      numberOne: file(relativePath: { eq: "numbers/1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 130, placeholder: BLURRED, layout: FIXED)
        }
      }
      numberTwo: file(relativePath: { eq: "numbers/2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 130, placeholder: BLURRED, layout: FIXED)
        }
      }
      numberThree: file(relativePath: { eq: "numbers/3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 130, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `);
  return (
    <ResponsiveList
      sliderProps={{
        darkControls: true,
        navOutside: true,
      }}
      classNameGridItemSetting={`md:w-1/3 px-10 py-4`}
    >
      <div>
        <div className="flex justify-center mb-4">
          <GatsbyImage
            image={threeStepsData.numberOne.childImageSharp.gatsbyImageData}
            alt="number one"
          />
        </div>
        <Heading variant="h5" className="text-center">
          Preventivo su misura
        </Heading>
        <p className="text-paragraph text-center mb-4">
          UGO ti accompagna e aiuta negli impegni di salute e quotidiani.
        </p>
      </div>
      <div>
        <div className="flex justify-center mb-4">
          <GatsbyImage
            image={threeStepsData.numberTwo.childImageSharp.gatsbyImageData}
            alt="number two"
          />
        </div>
        <Heading variant="h5" className="text-center">
          Paghi ciò che usi
        </Heading>
        <p className="text-paragraph text-center mb-4">
          Scegli tu come spostarti: a piedi, coi mezzi, con la macchina di UGO.
        </p>
      </div>
      <div>
        <div className="flex justify-center mb-4">
          <GatsbyImage
            image={threeStepsData.numberThree.childImageSharp.gatsbyImageData}
            alt="number three"
          />
        </div>
        <Heading variant="h5" className="text-center">
          Addebito post servizio
        </Heading>
        <p className="text-paragraph text-center mb-4">
          Operatori qualificati, servizio recensito, pagamento tracciato.
        </p>
      </div>
    </ResponsiveList>
  );
};

export default ThreeSteps;
