import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PartnerBannerQuery } from '../../../../generated/graphql-types';
import { convertToBgImage } from 'gbimage-bridge';

const PartnerBanner = () => {
  const partnerBannerData = useStaticQuery<PartnerBannerQuery>(graphql`
    query PartnerBanner {
      panorama: file(relativePath: { eq: "pricing/panorama.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const panorama = convertToBgImage(
    partnerBannerData.panorama.childImageSharp.gatsbyImageData
  );

  return (
    <BackgroundImage {...panorama}>
      <div className="bg-black bg-opacity-60 py-16 px-10 md:p-20">
        <Heading
          variant="h5"
          className="text-white text-center"
          style={{ color: '#ffffff' }}
        >
          Partner e Convenzioni
        </Heading>
        <p className="text-white text-paragraph text-center mb-5">
          Lavoriamo ogni giorno per stipulare convenzioni e partnership con
          strutture socio sanitarie e assistenziali ed altri erogatori di
          servizi alla persona.
        </p>
        <div className="flex justify-center">
          <a href="/i-partner-di-ugo/">
            <Button variant="filled" color="primary">
              Scopri le convenzioni attive
            </Button>
          </a>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default PartnerBanner;
